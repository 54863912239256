.backDrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000080;
  z-index: 10;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
  padding:16px;
  /* border-radius: 20px; */
  background-color: #FFFFFF;
}

.box__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.2px;
  color: #2F3567;
}

.box__bar {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  justify-content: center;
}

.box__bar__title {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 15.6px;
  color: #2F3567;

}

.box__bar__animate {
  position: relative;
  width: 100%;
  height: 2px;
  overflow: hidden; /* Ensures the animation stays inside the bar */
  background-color: #D9D9D9;
}

.box__bar__animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Initially spans full width */
  height: 100%;
  background-color: #FF6F3F; /* Orange line */
  animation: slide 1.5s linear infinite; /* Continuous animation */
  border-radius: 5px;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}