.container {
  position: relative;
  width: 100%;
}


.container__input {
  width: 100%;
  flex-grow: 1;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  padding: 8px 12px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #BAB9B9;

}

.container__input::-webkit-search-cancel-button {
  display: none;

}


.container__input::-webkit-search-decoration {

  display: none;

}



.container__input::placeholder {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #BAB9B9;
  padding-left: 4px;

}

.container__box {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #E0E0E0;
  cursor: pointer;
  z-index: 1;
  padding: 7px;
  border-radius: 4px;
  transform: translate(-4%, 10%);
  
}

.container__box__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container__box__btn {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  font-family: "Poppins", sans;
  color: #46CD80;

}

.container__box__btn__close {
  position: absolute;
  top: 10px;
  right: 12px;
  width: 16px;
  height: 16px;
  background-image: url("../../assets//close.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  padding: 7px;
  border-radius: 4px;
  transform: translate(-4%, 10%);
}