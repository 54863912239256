.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.box {
  padding: 16px 12px;
  border-radius: 8px;
  background-color: #FFFFFF;
  z-index: 11;
}