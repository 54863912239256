.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box__table {
  display: flex;
  justify-content: center;
  overflow: hidden;

}


.box__modelTable {
  width: 100%;
  border-collapse: collapse;
}

.box__modelTable__head {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  border-radius: 4px;
  background-color: #2F3567;
  color: #FFFFFF;
}


.box__modelTable__head__row__cell {
  padding: 8px;
}

.box__modelTable__head__row__cell:first-child {
  border-radius: 4px 0 0 4px;
}

.box__modelTable__head__row__cell:last-child {
  border-radius: 0 4px 4px 0;
}


.box__modelTable__body__row__data {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #313131;
  padding: 8px 0;
  text-align: center;

}


.box__modelTable__body__row__data__empty {
  font-family: "Poppins", sans;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #313131;
  padding: 8px 0;
  text-align: center;

}