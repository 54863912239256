.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
}


.box__order__detail {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

}