.box {
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 16px;
}
.box__grid{
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.box__upper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box__lower {
  display: flex;
  justify-content: space-between;
  column-gap: 120px;
}
.box__lower__content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}


.box__content{
 display: flex;
 column-gap: 12px;
 align-items: center;
 width: max-content;
}

.box__content__address {
  display: flex;
 column-gap: 12px;
 justify-content: center;

}

.box__content__key {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #989898;
}

.box__content__value {
  font-family: "Poppins", sans;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #2F3567;
}

.box__sep{
  border: 1px solid #EFEFEF;
}