.box {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;


}

.box__shipment {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 12px 16px
}

.box__shipment__left {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.box__shipment__head {
  font-family: "Poppins", sans;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 600;
  color: #2F3567;
}

.box__shipment__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  margin-right: 44px;

}

.box__shipment__download {
  border-radius: 4px;
  background-color: #46CD80;
  padding: 8px;
  font-family: "Poppins", sans;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 500;
  color: #FFFFFF;
}

.box_shipment__label {
  position: absolute;
  top: 20px;
  right: 16px;
  width: 16px;
  height: 16px;
  background-image: url(../../../../assets/shipmentExpand.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  transition: transform 0.3s ease;

}

.box__shipment__table {
  max-height: 0;
  overflow: hidden; /* Hide content overflow */
  opacity: 0; /* Optional: for fade effect */
  transition: max-height 0.9s ease, opacity 0.9s ease; /* Smooth transition */
}

.box__shipment__input {
  display: none;
}

.box__shipment__input:checked~.box_shipment__label {
  transform: rotate(180deg);
}

.box__shipment__input:checked~.box__shipment__table {
  max-height: 500px;
  opacity: 1;
  transition: max-height 0.9s ease, opacity 0.9s ease;
}