.box {
  width: fit-content;
  border-radius: 4px;
  border: 1px dashed #00A267;
  padding: 4px;
  cursor: pointer;
}



.box__label {
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: #00A167;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
 

}

.box__img {
  width: 12px;
  height: 12px;
  display: block;
  background-image: url("../../assets/share.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}



.box__text {
  font-family: "Poppins", sans;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #FFFFFF;

}

.box__upload {
  display: none;

}