.heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 12px;
  /* padding-top: 64px; */
  margin: 0 auto
}

.heading__box {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.heading__logo {
  display: block;
  width: 44px;
  height: 63px
}

.heading__title {
  font-family: "Poppins", sans;
  color: #2F3567;
  font-size: 28px;
  line-height: 37.7px;
}

.heading__subtitle {
  font-family: "Poppins", sans;
  color: #4A4C5F;
  font-size: 15px;
  font-weight: 400;
  line-height: 20.8px;
  text-align: left;
}

.heading__subtitle__highlight {
  font-family: "Poppins", sans;
  font-size: 16px;
  line-height: 16px;
  color: #FF6F3F;
  font-weight: 600;
}


.deals {
  display: flex;
  column-gap: 4px;
  align-items: center;
  padding-left: 8px;

}